import { Card, CardBody } from "reactstrap";
import ResultHandler from "../ResultHandler";
import "./CustomTable.css";
import i18next, { t } from "i18next";
import TableRow from "./TableRow";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useEffect } from "react";
import { useState } from "react";

export default function CustomTable({
  cols,
  data,
  emptyMsg,
  loading,
  error,
  isDnd,
  onUpdateOrder,
}) {
  const [rows, setRows] = useState(null);
  useEffect(() => {
    if (!rows) {
      setRows(data);
    }
  }, [data, rows]);

  /////
  const getColStyle = (col) => {
    return col.style.textAlign == "right"
      ? { ...col.style, textAlign: i18next.language == "ar" ? "left" : "right" }
      : col.style;
  };

  return (
    <DragDropContext
      onDragEnd={(r) => {
        if (isDnd) {
          setRows((oldRows) => {
            const result = Array.from(oldRows);
            const [removed] = result.splice(r.source.index, 1);
            result.splice(r.destination.index, 0, removed);
            onUpdateOrder(result);
            return result;
          });
        }
      }}
    >
      <Droppable droppableId="droppable-1">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <div>
              <Card>
                <CardBody className="">
                  <div className="custom-table-header">
                    <div className="custom-table-row d-flex align-items-center">
                      {cols.map((col, i) => (
                        <span
                          key={i}
                          style={{ fontWeight: "bold", ...getColStyle(col) }}
                        >
                          {t(col.label)}
                        </span>
                      ))}
                    </div>
                  </div>

                  <ResultHandler loading={loading} error={error} />

                  {rows &&
                    rows.map((row, i) => (
                      <TableRow
                        key={row.id}
                        cols={cols}
                        row={{
                          ...row,
                          order: i,
                        }}
                        getColStyle={getColStyle}
                        isDnd={isDnd}
                      />
                    ))}
                  {rows && rows.length == 0 && (
                    <p className="font-size-17 text-center mb-0 empty-table">
                      {t(emptyMsg)}
                    </p>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
