import Breadcrumbs from "../../../components/Common/Breadcrumb";
import React, { useState } from "react";
import { usePagination } from "../../../hooks/usePagination";
import ResultHandler from "../../../components/ResultHandler";
import PaginationHandler from "../../../components/PaginationHandler";
import {
  deleteMember,
  getMembers,
  updateMembersOrder,
} from "../../../api/members";
import TableAction from "../../../components/TableAction";
import { PERMISSIONS } from "../../../api/permissions";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { Button, Label } from "reactstrap";
import { useResponse } from "../../../hooks/useResponse";

export default function MembersList() {
  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
  } = usePagination(getMembers);

  const [filters, setFilters] = useState(false);

  //// update order

  const [newRows, setNewRows] = useState(null);

  const { loading: updateLoading, setData: setUpdateOrder } = useResponse(
    updateMembersOrder,
    () => {
      setNewRows(null);
      setPayload({})
    }
  );

  function handleUpdateOrder() {
    setUpdateOrder({
      type: "add",
      data: { members: newRows.map((m) => m.id) },
    });
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          isMainPage={true}
          title={"Members"}
          hasAddBtn={true}
          addUrl="/add-member"
        />
        <div
          className="d-flex mb-3"
          style={{ justifyContent: "space-between" }}
        >
          <div className="d-flex align-items-center" style={{ gap: "10px" }}>
            <Label>Type</Label>
            <select
              style={{ appearance: "auto", width: "230px" }}
              className="form-control"
              onChange={(e) => {
                setFilters(e.target.value == "false" ? false : true);
                setNewRows(null);
              }}
            >
              <option value={"false"}>Management</option>
              <option value={"true"}>Board</option>
            </select>
          </div>
          {newRows && (
            <Button
              onClick={updateLoading ? () => {} : handleUpdateOrder}
              size="lg"
              color="dark"
            >
              {updateLoading ? "Wait" : "Save Order"}
            </Button>
          )}
        </div>
        {!loading && <CustomTable
          isDnd={true}
          key={filters}
          onUpdateOrder={(updatedRows) => {
            setNewRows(updatedRows);
          }}
          data={response?.data?.members?.filter((m) => m.isFeatured == filters)}
          emptyMsg={"No Members Found."}
          loading={loading}
          error={error}
          cols={[
            {
              label: "Icon",
              key: "icon",
              renderer: (row) => (
                <img className="custom-rounded" src={row.img} />
              ),
              style: {
                width: "5%",
              },
            },
            { label: "Title", key: "title", style: { width: "25%" } },
            { label: "Job", key: "jop", style: { width: "30%" } },
            {
              label: "Type",
              key: "isFeatured",
              renderer: (row) => (row.isFeatured ? "Board" : "Management"),
              style: { width: "20%" },
            },

            {
              label: "Action",
              key: "action",
              style: { width: "20%", textAlign: "right" },
              renderer: (row) => {
                return (
                  <>
                    <TableAction
                      hasEdit={true}
                      hasDelete={true}
                      editPermission={PERMISSIONS.CATEGORY_EDIT}
                      deletePermission={PERMISSIONS.CATEGORY_DELETE}
                      id={row.id}
                      editUrl={"/edit-member/" + row.id}
                      deleteApi={deleteMember}
                      onDeleteSuccess={() => setPayload({})}
                    />
                  </>
                );
              },
            },
          ]}
        />}
        {response.data && !loading && (
          <PaginationHandler
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}{" "}
      </div>
    </div>
  );
}
