import { Draggable } from "react-beautiful-dnd";

export default function TableRow({ cols, row, getColStyle, isDnd }) {
  return (
    <div className="custom-table-row-wrapper">
      <Draggable
        isDragDisabled={!isDnd}
        draggableId={row.id.toString()}
        index={row.order}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div
              style={snapshot.isDragging ? { border: "0px" } : {}}
              className=" custom-table-row d-flex align-items-center"
            >
              {cols.map((col, i) => (
                <span key={i} style={getColStyle(col)}>
                  {(col.renderer ? col.renderer(row) : row[col.key]) || "-"}
                </span>
              ))}
            </div>
          </div>
        )}
      </Draggable>
    </div>
  );
}
